import "./assets/main.css"

import { createApp } from "vue"
import { createPinia } from "pinia"
import { resetStore } from "./reset-store"

import { appsignal } from "@/appsignal"
import { errorHandler } from "@appsignal/vue"

// Vuetify
import "vuetify/styles"
import { createVuetify } from "vuetify"
import * as components from "vuetify/components"

// Prevent console errors as per https://www.answeroverflow.com/m/1158575183582462002
// TODO: this works, but still need to prevent TS type errors in IDE
// import * as allComponents from 'vuetify/components';
// const components = {...allComponents};
// delete components.VTooltip;
// delete components.VMenu;
// import { VTooltip as VuetifyTooltip, VMenu as VuetifyMenu } from 'vuetify/components';
// components.VuetifyTooltip = VuetifyTooltip;
// components.VuetifyMenu = VuetifyMenu;

import * as directives from "vuetify/directives"
import "@mdi/font/css/materialdesignicons.css"
import "vuetify/dist/vuetify.min.css"

import App from "./App.vue"
import router from "./router"

import Toast, { POSITION } from "vue-toastification"
import "vue-toastification/dist/index.css"
import { registerSW } from "virtual:pwa-register"

import VueApexCharts from "vue3-apexcharts"
import VueJsonPretty from "vue-json-pretty"
import "vue-json-pretty/lib/styles.css"
import colors from "vuetify/lib/util/colors.mjs"

import NotificationCenterPlugin from "@novu/notification-center-vue"
import "@novu/notification-center-vue/dist/style.css"

import "flag-icons/css/flag-icons.min.css"
import "v-phone-input/dist/v-phone-input.css"
import { createVPhoneInput } from "v-phone-input"

window.addEventListener("vite:preloadError", (event) => {
  // window.reload()
  window.location.reload()
})

registerSW({ immediate: true })

const app = createApp(App).use(NotificationCenterPlugin)
const pinia = createPinia()
const vuetify = createVuetify({
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          appbar: "#ffffff",
          sidebar: "#1e293b",
          background: "#f2f2f2",
          surface: "#ffffff",
          "on-surface": "#333333",
          primary: colors.blue.base,
          "grey-darken-4": "#1c1c1c",
          "grey-darken-5": "#141414",
          "green-darken-5": "#073823",
          green: "#30AB3C",
          "green-lighten-5": "#1FE0B9",
          "surface-lighten-1": "#222549",
          warning: "#ce7f25",
          "red-lighten-6": "#fcedf2",
          "surface-dark": colors.grey.lighten3,
        },
        variables: {
          "divider-border-color": "#333333",
        }
      },
      dark: {
        colors: {
          // sidebar: "#000000",
          // appbar: "#000000",
          primary: colors.indigo.base,
          success: colors.green.darken1,
          // "surface-dark": "#181818",
          // "on-surface": "#f2f2f2",
        },
        variables: {
          "divider-border-color": "#f8f8f8",
        }
      },
    },
  },
  components,
  directives,
})
const vPhoneInput = createVPhoneInput({
  countryIconMode: "svg",
  preferCountries: ["US", "CA"],
})
app.config.errorHandler = errorHandler(appsignal, app)
app.use(vuetify)
app.use(pinia)
pinia.use(resetStore)
app.use(router)
app.use(Toast, {
  position: POSITION.TOP_RIGHT,
  timeout: 3000,
})
app.use(VueApexCharts)
app.use(vPhoneInput)
app.component("vue-json-pretty", VueJsonPretty)
app.mount("#app")
