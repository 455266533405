<script setup>
import { ref, computed, onMounted, onBeforeMount, watch } from "vue";
import { useUserStore } from "../stores/user";
import { useSidebarStore } from "../stores/sidebarStore";
import { useRoute, useRouter } from "vue-router/auto";
import { TRANSPORT_ORDERS_ROUTE } from "@/constants/constants.js";

const drawer = ref(true);
const userStore = useUserStore();
const sidebarStore = useSidebarStore();
const route = useRoute();
const expandedSubmenus = ref({});
const router = useRouter();
const links = [
  {
    icon: "mdi-home-outline",
    title: "Home",
    to: "/dashboard",
    value: "home",
  },

  {
    icon: "mdi-truck-cargo-container",
    title: "Orders",
    value: "orders",
    superadmin_only: true,
    children: [
      {
        icon: "mdi-truck-delivery",
        title: "Inbound",
        initials: "IB",
        to: "/inbound-orders",
        value: "inbound-orders",
        manager_only: true,
      },
      {
        icon: "mdi-truck-delivery",
        title: "Outbound",
        initials: "OB",
        to: "/outbound-orders",
        value: "outbound-orders",
        manager_only: true,
      },
      {
        icon: "mdi-truck-outline",
        title: "Transport",
        to: `/${TRANSPORT_ORDERS_ROUTE}`,
        value: `${TRANSPORT_ORDERS_ROUTE}`,
        superadmin_only: true,
      },
      {
        icon: "mdi-star-outline",
        title: "SLOs",
        to: "/special-orders",
        value: "special-orders",
        superadmin_only: true,
      },
      {
        icon: "mdi-cart-outline",
        title: "Ecommerce",
        to: "/ecommerce",
        value: "ecommerce",
        superadmin_only: true,
      },
    ],
  },
  {
    icon: "mdi-database-edit-outline",
    title: "EDI",
    to: "/edi-requests",
    value: "edi-requests",
    manager_only: true,
  },
  {
    icon: "mdi-package-variant-closed",
    title: "Products",
    to: "/products",
    value: "products",
    superadmin_only: true,
  },
  {
    icon: "mdi-magnify",
    title: "Pallets",
    to: "/pallet-search",
    value: "pallet-search",
    superadmin_only: true,
  },
  {
    icon: "mdi-map-marker-outline",
    title: "Bins",
    to: "/bins",
    value: "bins",
    superadmin_only: true,
  },
  {
    icon: "mdi-currency-usd",
    title: "Billing Manager",
    value: "billing_manager",
    to: "",
    children: [
      {
        icon: "mdi-receipt-text-outline",
        title: "Billing",
        to: "/billings",
        value: "billing",
        required_user_permission: "edit_billing",
      },
      {
        icon: "mdi-invoice",
        title: "Invoices",
        to: "/invoices",
        value: "invoices",
        required_user_permission: "edit_billing",
      },
      {
        title: "Default Billing Rates",
        to: "/billings/default-billing-rates",
        value: "default-billing-ates",
        required_user_permission: "edit_billing",
      },
    ],
  },
  {
    icon: "mdi-tie",
    title: "Clients",
    to: "/clients",
    value: "clients",
    superadmin_only: true,
  },
  {
    icon: "mdi-earth",
    title: "Carriers",
    value: "carriers",
    to: "/carriers",
    superadmin_only: true,
  },
  {
    icon: "mdi-glass-fragile",
    title: "Damage Reports",
    to: "/damage-reports",
    value: "damage-reports",
    superadmin_only: true,
  },
  {
    icon: "mdi-warehouse",
    title: "Warehouses",
    to: "/warehouses",
    value: "warehouses",
  },
  {
    icon: "mdi-account-multiple-outline",
    title: "Users",
    value: "users",
    required_user_permission: "view_users",
    to: "/users",
  },
  {
    icon: "mdi-calendar",
    title: "Schedule",
    value: "schedule",
    to: "/schedule",
    superadmin_only: true,
  },
  {
    icon: "mdi-ticket-outline",
    title: "Support Tickets",
    value: "support-tickets",
    to: "/support-tickets",
  },
  {
    icon: "mdi-dots-horizontal-circle-outline",
    title: "More",
    value: "More",
    children: [
      {
        icon: "mdi-notebook-outline",
        title: "Changelogs",
        to: "/changelogs",
        value: "changelogs",
      },
      {
        icon: "",
        title: "Support Settings",
        to: "/support-tickets/settings",
        value: "support-ticket-settings",
        required_support_ticket_role: ["superadmin"],
      },
      {
        icon: "mdi-domain",
        title: "Tenants",
        to: "/tenants",
        value: "tenants",
        superadmin_only: true,
      },
      {
        icon: "mdi-upload-multiple-outline",
        title: "Uploaders",
        value: "uploaders",
        to: "/csvupload",
      },
      {
        title: "WooCommerce Users",
        to: "/woocommerce/users",
        value: "woocommerce-users",
        superadmin_only: true,
      },
      {
        title: "Woocommerce Logs",
        to: "/woocommerce/logs",
        value: "woocommerce-logs",
        superadmin_only: true,
      },
    ],
  },
  {
    title: "Back to Connect",
    icon: "mdi-arrow-left",
    href: import.meta.env.VITE_CONNECT_URL,
    value: "back-to-connect",
  },
];

const cardClass = computed(() => {
  return route.path !== "/" ? "bg-sidebar" : "";
});

const isLinkVisible = (link) => {
  const isManager = userStore.user?.employee_profile?.is_manager;
  const userRole = userStore.user.permissions.support_ticket_role;
  const managerOnlyCheck = !link.manager_only || isManager;
  const roleCheck =
    !link.required_support_ticket_role ||
    link.required_support_ticket_role.includes(userRole);

  const userPermissions = userStore.user?.permissions;
  const permissionCheck =
    !link.required_user_permission || userPermissions[link.required_user_permission];

  return managerOnlyCheck && roleCheck && permissionCheck;
};

const filteredLinks = computed(() => {
  return links.filter((link) => {
    if (link.hidden) {
      return false;
    }

    if (link.children) {
      // Filter children links based on the visibility criteria
      link.children = link.children.filter(isLinkVisible);
      // Only keep this link if it has any visible children after filtering
      return link.children.length > 0;
    }
    // For links without children, directly apply the visibility criteria
    return isLinkVisible(link);
  });
});

// This is a weird fix for the VListGroup submenu status getting out of sync on initial page load if any are open
onBeforeMount(() => {
  expandedSubmenus.value = {
    "edi-requests": false,
    settings: false,
  };
});

const popoverButton = ref(null);

// Load expanded submenu states from local storage when the component is mounted
onMounted(() => {
  const savedExpandedSubmenus = localStorage.getItem("expandedSubmenus");
  if (savedExpandedSubmenus) {
    expandedSubmenus.value = JSON.parse(savedExpandedSubmenus);
  }
  const savedShowRail = localStorage.getItem("showRail");
  if (savedShowRail) {
    sidebarStore.collapse(JSON.parse(savedShowRail));
  }
});

watch(
  expandedSubmenus,
  (newVal) => {
    localStorage.setItem("expandedSubmenus", JSON.stringify(newVal));
  },
  { deep: true }
);

watch(sidebarStore, (newVal) => {
  if (newVal) {
    localStorage.setItem("showRail", newVal.showRail);
  }
});

function logProps(props) {
  console.log(props);
}

const getInitials = (title) => {
  let split = title.split(" ");
  if (split.length > 1) {
    return split
      .map((word) => word[0])
      .join("")
      .toUpperCase()
      .slice(0, 3);
  } else {
    return title.toUpperCase().slice(0, 3);
  }
};

function isActiveLink(link) {
  return link.to === route.path;
}
</script>

<template>
  <v-navigation-drawer
    v-if="userStore.user"
    v-model="drawer"
    style="z-index: 1000"
    :rail="sidebarStore.showRail"
    permanent
  >
    <v-list
      density="compact"
      nav
      class="d-flex flex-column"
    >
      <template
        v-for="link in filteredLinks"
        :key="link.value"
      >
        <v-tooltip
          :disabled="!sidebarStore.showRail"
          :text="link.title"
          location="right"
        >
          <template v-slot:activator="{ props: tooltipProps }">
            <v-list-item
              v-if="!link.children"
              v-bind="sidebarStore.showRail ? tooltipProps : {}"
              :to="link?.to"
              :href="link?.href"
            >
              <template v-slot:prepend>
                <v-icon>{{ link.icon }}</v-icon>
              </template>
              <v-list-item-title>{{ link.title }}</v-list-item-title>
            </v-list-item>

            <v-list-group
              v-else
              v-model="expandedSubmenus[link.value]"
              :value="link.value"
            >
              <template v-slot:activator="{ props: listGroupProps }">
                <v-list-item v-bind="{
                  ...listGroupProps,
                  ...(sidebarStore.showRail ? tooltipProps : {}),
                }">
                  <template v-slot:prepend="{ isActive }">
                    <v-icon>{{ link.icon }}</v-icon>
                  </template>
                  <template v-slot:default="{ isActive }">
                    <v-list-item-title>{{ link.title }}</v-list-item-title>
                  </template>
                </v-list-item>
              </template>

              <v-tooltip
                v-for="subLink in link.children"
                :key="subLink.value"
                :disabled="!sidebarStore.showRail"
                :text="subLink.title"
                location="right"
              >
                <template v-slot:activator="{ props: subTooltipProps }">
                  <v-list-item
                    v-bind="sidebarStore.showRail ? subTooltipProps : {}"
                    :to="subLink.to"
                    :key="subLink.value"
                    :class="{
                      'sub-item-closed': sidebarStore.showRail,
                    }"
                    class="bg-transparent"
                    exact
                  >
                    <template
                      v-slot:prepend="{ isActive }"
                      v-if="sidebarStore.showRail"
                    >
                      <div
                        :class="{

                        }"
                        class="text-caption d-flex text-xsmall justify-center align-center items-center rounded-xl"
                        :style="`width: 26px; height: 26px; font-size: 10px !important; margin-left: ${sidebarStore.showRail ? '0.17rem' : '0'
                          }`"
                      >
                        {{ getInitials(subLink.initials || subLink.title) }}
                      </div>
                    </template>
                    <template v-slot:default="{ isActive }">
                      <v-list-item-title
                        class="ml-2"
                        v-if="!sidebarStore.showRail"
                      >
                        {{ subLink.title }}
                      </v-list-item-title>
                    </template>
                  </v-list-item>
                </template>
              </v-tooltip>
            </v-list-group>
          </template>
        </v-tooltip>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<style scoped>
.custom-avatar {
  font-size: 12px !important;
  letter-spacing: -0.5px;
}

.v-list-item.sub-item-closed {
  padding-left: 0.25rem !important;
}

.v-list-group__items .v-list-item.sub-item .v-avatar {}

.v-navigation-drawer--rail .v-list-group__items .v-list-item.sub-item .v-list-item__content {
  opacity: 0;
}

.v-navigation-drawer--rail .v-list-item__prepend {}
</style>
