<script setup>
import { watch } from "vue";
import { useLoadingStore } from "@/stores/loading";
import { useSidebarStore } from "@/stores/sidebarStore";
import pageTitle from "@/utils/pageTitle";
import Sidebar from "@/components/Sidebar.vue";
import AppBar from "@/components/layout/AppBar.vue";

const loadingStore = useLoadingStore();
const sidebarStore = useSidebarStore();

watch(pageTitle, (newValue) => {
  document.title = newValue + " | L&M Connect3";
});

const getNavIcon = () => {
  if (sidebarStore.showRail) {
    return "mdi-chevron-right";
  } else {
    return "mdi-chevron-left";
  }
};
</script>

<template>
  <v-app>

    <AppBar />
    <Sidebar />
    <v-main style="min-height: 100vh">
      <slot />
    </v-main>


    <v-overlay
      v-bind:close-on-content-click="false"
      v-model="loadingStore.isLoading"
      persistent
      class="d-flex justify-center align-center"
    >
      <v-progress-circular
        :width="15"
        :size="100"
        color="primary"
        class="mb-16"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>
